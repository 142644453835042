import CryptoJS from 'crypto-js';
import { getLocalToken } from '../../services/utils';


function stringToArrayBuffer(str: string): ArrayBuffer {
  const buf = new ArrayBuffer(str.length);
  const bufView = new Uint8Array(buf);
  for (let i = 0; i < str.length; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
}

// Convertir una clave en formato string a CryptoKey
async function importKeyFromString(keyString: string): Promise<CryptoKey> {
  const rawKey = stringToArrayBuffer(keyString);
  return crypto.subtle.importKey(
    "raw",
    rawKey,
    "AES-CTR",
    true,
    ["encrypt", "decrypt"]
  );
}

// Función para cifrar un texto
async function encrypt(text: string, key: CryptoKey): Promise<{ iv: string, ciphertext: string }> {
  const encoder = new TextEncoder();
  const data = encoder.encode(text);
  const iv = crypto.getRandomValues(new Uint8Array(16)); // Vector de inicialización

  const ciphertextBuffer = await crypto.subtle.encrypt(
    {
      name: "AES-CTR",
      counter: iv,
      length: 64,
    },
    key,
    data
  );

  // Convertir ArrayBuffer a base64
  const ivString = btoa(String.fromCharCode(...iv));
  const ciphertextString = btoa(String.fromCharCode(...new Uint8Array(ciphertextBuffer)));

  return { iv: ivString, ciphertext: ciphertextString };
}
// Función para descifrar un texto
// Función para descifrar un texto
async function decrypt(ciphertext: string, key: CryptoKey, iv: string): Promise<string> {
  // Convertir base64 a ArrayBuffer
  const ivUint8Array = Uint8Array.from(atob(iv), c => c.charCodeAt(0));
  const ciphertextUint8Array = Uint8Array.from(atob(ciphertext), c => c.charCodeAt(0));

  const decryptedBuffer = await crypto.subtle.decrypt(
    {
      name: "AES-CTR",
      counter: ivUint8Array,
      length: 64,
    },
    key,
    ciphertextUint8Array
  );

  const decoder = new TextDecoder();
  return decoder.decode(decryptedBuffer);
}


async function SuperEncripter(text: string) {
  const token = await getLocalToken();

  if (!token) {
    throw new Error("No se pudo obtener el token local.");
  }



  const key = await importKeyFromString(token);
  const { iv, ciphertext } = await encrypt(text, key);

  

  return `${iv}:${ciphertext}`
}

async function SuperDescripter(text: string) {

  const token = await getLocalToken();

  if (!token) {
    throw new Error("No se pudo obtener el token local.");
  }


  
  const [iv, ciphertext] = text.split(":")

  const key = await importKeyFromString(token);
  const decryptedText = await decrypt(ciphertext, key, iv);

  return decryptedText
}


export { SuperEncripter, SuperDescripter };
