import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { Provider as ReduxProvider } from "react-redux";
import { store } from "./store";
import Spinner from "./components/spinner/Spinner";
import "./index.css";
import {
  initializationService,
  nextService,
} from "./services/bootstrap-services";
import RobotContextProvider from "./contexts/RobotContext";
import ConversationContextProvider from "./contexts/ConversationContext";
import { SocketProvider } from "./contexts/SocketContext";

const initialization = async () => {
  try {
    let result = await initializationService();
    localStorage.setItem("session", result.init);
    let publickey = await nextService({ data: "string" });
    localStorage.setItem("out", publickey.access_token);

    const App = lazy(() => import("./App"));

    const root = ReactDOM.createRoot(
      document.getElementById("root") as HTMLElement
    );

    root.render(
      <React.StrictMode>
        <ReduxProvider store={store}>
          <SocketProvider>
            <RobotContextProvider>
              <Suspense fallback={<Spinner />}>
                <Router>
                  <App />
                </Router>
              </Suspense>
            </RobotContextProvider>
          </SocketProvider>
        </ReduxProvider>
      </React.StrictMode>
    );
  } catch (error) {
    console.error(error);
  }
};

initialization();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
