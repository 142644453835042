// Types
import { createSlice } from "@reduxjs/toolkit";

// Initial state
const initialState = {
  debtor_id: "", // driverId
  debtor_name: "", // driverName
  debtor_email: "", // driverEmail
  debtor_rut: "", // driverRut
  debtor_phone: "", // driverPhone
  debtor_address: "", // driverAddress
  owner_id: "", // ownerId
  owner_name: "", // ownerName
  owner_email: "", // ownerEmail
  owner_rut: "", // ownerRut
  owner_phone: "", // ownerPhone
  owner_address: "", // ownerAddress
  plate_id: "", // idPlate
  plate_number: "", // numberPlate
  brand:"",
  model:""
};

// ==============================|| SLICE - CASES ||============================== //

const cases = createSlice({
  name: "case",
  initialState,
  reducers: {
    addDebtorInfo(state, action) {
      state.debtor_id = action.payload.id;
      state.debtor_name = action.payload.name;
      state.debtor_rut = action.payload.rut;
      state.debtor_phone = action.payload.phone;
      state.debtor_email = action.payload.email;
      state.debtor_address = action.payload.address;
    },
    addOwnerInfo(state, action) {
      state.owner_id = action.payload.id;
      state.owner_name = action.payload.name;
      state.owner_rut = action.payload.rut;
      state.owner_phone = action.payload.phone;
      state.owner_email = action.payload.email;
      state.owner_address = action.payload.address;
    },
    addPlateInfo(state, action) {
      state.plate_id = action.payload.id;
      state.plate_number = action.payload.plate;
      state.brand = action.payload.brand;
      state.model = action.payload.model
    },
    cleaningCaseState(state, action) {
      // cleaning state
      state.debtor_id = "";
      state.debtor_name = "";
      state.debtor_rut = "";
      state.debtor_phone = "";
      state.debtor_email = "";
      state.debtor_address = "";
      state.owner_id = "";
      state.owner_name = "";
      state.owner_rut = "";
      state.owner_phone = "";
      state.owner_email = "";
      state.owner_address = "";
      state.plate_id = "";
      state.plate_number = "";
    },
  },
});

export default cases.reducer;

export const { addDebtorInfo, addPlateInfo, cleaningCaseState, addOwnerInfo } = cases.actions;
