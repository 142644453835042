// Types
import { createSlice } from '@reduxjs/toolkit';

// Initial state
const initialState = {
    userId: ""
};

// ==============================|| SLICE - USER ||============================== //


const user = createSlice({
    name: 'user',
    initialState,
    reducers: {
        activeUserId(state, action) {
            state.userId = action.payload.userId;
        },

        deactiveUserId(state, action) {
            state.userId = "";
        },
    }
});

export default user.reducer;

export const { activeUserId, deactiveUserId } = user.actions;
