import { combineReducers } from "redux";
// Project import
import menu from "./menu";
import user from "./user";
import cases from "./case";
import toast from "./toast";

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({ menu, user, cases, toast });

export default reducers;
