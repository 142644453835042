import config from "../../environment";
import { callApi } from "../utils";

// iNITIALIZATION
export const initializationService = async () => {
  const url = `${config.apiUrl}/api/v1/auth/init`;
  const json = await callApi({
    url: url,
    method: "GET",
    token: "",
    body: null,
  });
  return json;
};

// NEXT
export const nextService = async (requestData: any) => {
  const url = `${config.apiUrl}/api/v1/auth/token`;
  const json = await callApi({
    url: url,
    method: "POST",
    token: "",
    body: requestData,
  });
  return json;
};
