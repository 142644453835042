// src/socket.ts
import { io, Socket } from 'socket.io-client';

const URL = process.env.REACT_APP_SOCKET as string; // Asegúrate de que esta URL sea la de tu servidor WebSocket

const socket: Socket = io(URL, {
  autoConnect: false,
  transports: ['websocket'],
});

export default socket;
