// Thirds
import { configureStore } from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";
// Project import
import reducers from "./reducers";



// ==============================|| REDUX TOOLKIT - MAIN STORE ||============================== //

const logger = createLogger();

const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoreActions: true,
      },
    }).concat(logger),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

const { dispatch } = store;

export { store, dispatch };
